<template>
  <div>
    <content-template
      :menuName="$t('APMA.assessmentDetail')"
      :backButton="true"
      @backToPage="moveToOther('APMA')"
    >
      <template #detail>
        <table v-if="!isMobile">
          <tr v-for="(t, i) in fields" :key="`task-${i}`">
           
              <td class="s-input-label s-wd-200-min s-ptb-10">{{ $t(t.name) }}</td>
              <td>:</td>
              <td v-if="t.variable == 'stakeholders' || t.variable == 'leads'" class="s-ptb-10">
                <span
                  class="s-text-black"
                  v-for="(s, j) in item[t.variable]"
                  :key="`stake-${j}`"
                >
                  {{ s.name }}
                  <span
                    class="s-text-black"
                    v-if="j < item[t.variable].length - 1"
                    >|
                  </span>
                </span>
              </td>
              <td
              class="s-ptb-10"
                v-else-if="
                  t.variable == 'start_date' || t.variable == 'end_date'
                "
              >
                {{ convertDate(item[t.variable]) }}
              </td>
              <td v-else class="s-ptb-10">
                {{ item[t.variable] }}
              </td>
          
          </tr>
        </table>

        <div v-else>
          <div v-for="(t, i) in fields" :key="`task-alt-${i}`">
            <div class="i-table-cell-alt">
              <span class="i-input-label"
                ><b>{{ $t(t.name) }}</b></span
              >
              <br />

              <span
                v-if="t.variable == 'stakeholders' || t.variable == 'leads'"
              >
                <span
                  class="s-text-black"
                  v-for="(s, j) in item[t.variable]"
                  :key="`stake-${j}`"
                >
                  {{ s.name }}
                  <span
                    class="s-text-black"
                    v-if="j < item[t.variable].length - 1"
                    >,
                  </span>
                </span>
              </span>
              <span
                class="s-text-black"
                v-else-if="
                  t.variable == 'start_date' || t.variable == 'end_date'
                "
              >
                {{ convertDate(item[t.variable]) }}
              </span>
              <span class="s-text-black" v-else>
                {{ item[t.variable] }}
              </span>
            </div>
          </div>
        </div>

        <br />
        <Button
          v-if="checkNewStatus"
          class="s-flex s-ml-auto"
          @click="moveToFinish('submit')"
          >{{ $t("APMA.startActionPlan") }}</Button
        >
        <Button
          v-if="checkLeads && item.status != 'Finish'"
          class="s-flex s-ml-auto"
          @click="moveToFinish('submit')"
          >{{
            item.status == "On Progress"
              ? $t("APMA.finishTask")
              : item.status == "New"
              ? $t("APMA.startActionPlan")
              : item.status == "In Review"
              ? $t("APMA.editTask")
              : ""
          }}</Button
        >

        <Button
          class="s-flex s-ml-auto"
          @click="moveToFinish()"
          v-if="role_type == 'expert'"
          >Feedback</Button
        >
      </template>
    </content-template>

    <Modal v-if="dialogDate == 'showModalDate'" :title="$t('general.date')">
      <template #modalContent>
        <Input
          :label="$t('APMA.startDate')"
          type="date"
          v-model="start_date"
          class="s-mb-20"
        />
        <div class="s-mtb-20 s-flex">
          <div class="s-ml-auto">
            <Button class="s-ml-20" @click.prevent="onFinishDate">
              {{ $t("general.submit") }}
            </Button>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import form from "@/mixins/form";
import { mapActions, mapGetters } from "vuex";
import ContentTemplate from "./contentTemplate.vue";
import { convertDate } from "@/common/timeformat";

export default {
  components: { ContentTemplate },
  props: ["inputType"],
  mixins: [form],
  data() {
    return {
      checkLeads: false,
      checkNewStatus: false,
      start_date: "",
      edited_item: {
        cycle_phase: "",
        priority: "",
        measure: "",
        detail: "",
        stakeholder_agencies: [],
        leads: [],
        expected_result: "",
        expected_timeline: "",
      },

      fields: [
        { name: "APMA.DRMCyclePhase", variable: "cycle_phase_name" },
        { name: "APMA.priority", variable: "priority_name" },
        { name: "APMA.measure", variable: "measure" },
        { name: "APMA.stakeholderAgencies", variable: "stakeholders" },
        { name: "APMA.leads", variable: "leads" },
        { name: "APMA.expectedResult", variable: "expected_result" },
        { name: "APMA.expectedTimeline", variable: "expected_timeline" },
        { name: "APMA.startDate", variable: "start_date" },
        { name: "APMA.endDate", variable: "end_date" },
      ],
    };
  },
  mounted() {
    this.initData();
  },
  computed: {
    ...mapGetters({
      item: "actionplan/getActionPlan",
      role_type: "auth/getRole",
      user_data: "auth/getUser",
    }),
    isMobile() {
      return this.$store.getters["getIsMobile"];
    },
    dialogDate() {
      return this.$store.getters["getModal"];
    },
  },
  methods: {
    ...mapActions({
      setModal: "setModal",
      setActionPlanSingleData: "actionplan/setActionPlanSingleData",
      startActionPlan: "actionplan/startActionPlan",
    }),

    async initData() {
      this.setLoadingPage(true);
      this.setModal(false);

      await this.setActionPlanSingleData(this.$route.params.id);
      const leads = this.item.leads;
      leads.forEach((el) => {
        if (el.id == this.user_data.id) {
          this.checkLeads = true;
        }
        if (el.id == this.user_data.id && el.status == "New") {
          this.checkNewStatus = true;
        }
      });
      this.setLoadingPage(false);
    },

    async onFinishDate() {
      const data = {
        start_date: this.start_date,
        id: this.$route.params.id,
      };
      await this.startActionPlan(data);
      this.setModal(null);
      this.$router.push(
        this.$translate({
          name: "APMA",
        })
      );
    },
    convertDate(value) {
      return convertDate(value);
    },
    moveToFinish(param) {
      if (this.item.status == "New") {
        this.setModal("showModalDate");
      } else {
        this.$router.push(
          this.$translate({
            name: "APMA Finish Task",
            params: { id: this.item.id, inputType: param },
          })
        );
      }
    },
  },
};
</script>
